import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h2>
          Welcome to <a className="home__link" href="https://curtainfy.com">curtainfy</a>
        </h2>
        <p>
          Made by <a className="home__link" href="https://linkedin.com/in/arugyani">Aru</a>, {" "}
          <a className="home__link" href="https://www.linkedin.com/in/lillie-mcmaster/">Lillie</a>, {" "}
          <a className="home__link" href="https://www.linkedin.com/in/divyam-khatri/">Divyam</a>, {" "}
          <a className="home__link" href="https://www.linkedin.com/in/prajwal-nellogi-85bbb21a0/">Prajwal</a>, and {" "}
          <a className="home__link" href="https://www.linkedin.com/in/avinash-tumu-4943b11b6/">Avinash</a>
        </p>
      </header>
    </div>
  );
}

export default App;
